import React from "react";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";

/**
 * This component renders better model in dicom-nifti
 * @param {string} {location} location of parent page
 * @param {string} {heading} data from prismic
 * @param {string} {description} data from prismic
 * @param {string} {ctaHeading} data from prismic
 * @param {string} {ctaDescription} data from prismic
 * @param {object} {modelTypes} data from prismic
 * @param {string} {pageName} name of parent page
 * @author Waqas Ahmad
 * @returns JSX.Element
 */
const BetterModels = ({
  location,
  heading,
  label,
  ctaHeading,
  modelTypes,
  pageName,
  formId,
  allowAllEmails,
}) => {
  return (
    <article className="relative pt-16 pb-16 overflow-hidden sm:pt-20 sm:pb-22 lg:pt-16 lg:pb-64">
      <section className="mx-auto max-w-7xl">
        <section className="z-10 flex flex-col justify-between gap-5 text-white">
          <section className="flex-1 lg:max-w-md xl:max-w-none">
            <h2 className="text-center text-[#C5BBFF] uppercase -tracking-tightest font-medium mb-3 sm:mb-4 lg:mb-6 text-sm sm:text-base">
              {label}
            </h2>
            <h3 className="text-2.5xl sm:text-3.75xlxl font-semibold text-center">
              {heading}
            </h3>
          </section>

          <section className="flex flex-col justify-between mx-auto lg:flex-row lg:gap-x-24 gap-y-7 lg:py-18 py-14 sm:max-w-xl lg:max-w-7xl">
            {modelTypes?.map((item, index) => {
              const {
                section_feature_icon,
                section_feature_heading,
                section_feature_description,
              } = item || {};
              return (
                <section
                  className="flex flex-col items-center gap-5 sm:items-start sm:flex-row"
                  key={index}
                >
                  <img
                    src={section_feature_icon?.url}
                    width={0}
                    height={0}
                    alt={section_feature_icon?.alt || "img"}
                    loading="lazy"
                    className="flex items-center justify-center h-auto w-18 ounded-lg"
                  />
                  <section className="text-center sm:text-left">
                    <h6 className="text-xl font-semibold lg:text-2xl">
                      {section_feature_heading?.text}
                    </h6>
                    <p className="px-4 mt-2 text-sm sm:text-base sm:px-0">
                      {section_feature_description?.text}
                    </p>
                  </section>
                </section>
              );
            })}
          </section>
        </section>

        <section className="w-full max-w-5xl mx-auto text-center text-white">
          <h1 className="text-2xl lg:text-3.75xl sm:text-3.25xl font-semibold w-full lg:max-w-full sm:max-w-xl mx-auto leading-tight mb-9">
            {ctaHeading}
          </h1>

          <section className="flex justify-center">
            <NewHomeLeadFormWithAPIForm
              formId={formId || "9d96ba5b-2fce-4492-8d9d-34478ab8fd25"}
              pageName={pageName}
              location={location}
              btnText="Book demo"
              routerId="768"
              allowAllEmails={allowAllEmails}
              isCustomBtnBg={true}
              btnColor="dicom-btn border-0 px-5 h-[50px]"
            />
          </section>
        </section>
      </section>
    </article>
  );
};

export default BetterModels;
